.gridrequest {
  display: grid;
  width: 85%;
  background-color: black;
  padding: 20px;
  border-radius: 7px;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

.maingridrequest {
  display: flex;
  justify-content: center;
}

.blockrequest {
  background-color: #0c1318;
  padding: 20px;
  border-radius: 7px;
  height: fit-content;
}

/* Custom dark theme for toast notifications */
.react-toastify.toast-dark {
  background-color: #333;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Custom dark theme for toast success */
.react-toastify.toast-dark.toast-success {
  background-color: #007BFF;
}

/* Custom dark theme for toast error */
.react-toastify.toast-dark.toast-error {
  background-color: #FF0000;
}

.iconsreq svg {
  fill: #ffba00;
  color: #ffba00;
  width: 17px;
  height: 17px;
  margin-right: 10px;
}

.iconsreq {
  display: flex;
  width: fit-content;
  color: #ffba00;
  min-width: 230px;
  padding: 10px 30px;
  justify-content: center;
  font-size: 17px;
  background-color: rgba(256, 187, 0, 0.1);
  border-radius: 10px;
  border: 1px solid #ffba00;
}

.iconsreq p {
  color: #ffba00;
  font-size: 13px;
}

@media (max-width: 768px) {
  .gridrequest {
      width: 95%;
      padding: 20px; /* Reduce padding for smaller screens */
      grid-template-columns: 1fr;
  }

  .blockrequest {
      padding: 10px; /* Reduce padding for smaller screens */
      width: 92%;
      margin-top: 20px;
  }

}
