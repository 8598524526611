.status-message {
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
  }
  
  /* Styling for success status messages */
  .status-message.success {
    background-color: #4caf50; /* Green */
    color: white;
  }
  
  /* Styling for error status messages */
  .status-message.error {
    background-color: #f44336; /* Red */
    color: white;
  }

  .choose-input-file {
    margin:10px 0;
    label {
      svg {
        width: 18px;
      }
      input {
        display: none;
      }
      .flex-item {
        gap: 6px;
        background: transparent;
        border-radius: 8px;
        padding: 10px 12px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        border: 1px dashed #ffba00;
      }
    }
  }


  .html-no-scroll, .body-no-scroll {
    overflow: hidden;
  }

/* Scrollbar Track */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px; /* Adjust the scrollbar width as needed */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #000; /* Track color */
}

/* Scrollbar Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #a37800; /* Handle color */
  border-radius: 20px; /* Handle rounded corners */
}

/* Scrollbar Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #000; /* Handle color on hover */
}

.sscamrequestdiv{
    
  display: flex;
  width: 100%;
  
  margin-top: 20px;
  justify-content: center;
  gap: 20px;
  align-items: center;
    }


.cancel{
  width:180px;
}


@media (max-width: 768px) {
  


  .custom-button-request{
    background-color:rgba(256,187,0,0.1);
    color:#ffba00;
    border:1px solid #ffba00;
    padding:10px 38px;
    font-size:12px;
    width:280px;
    margin-bottom:10px;
    border-radius:7px;
  }

  .sscamrequestdiv{
    
    display: block;
    width: 100%;
    
    margin-top: 20px;
    justify-content: center;
    gap: 20px;
    align-items: center;
      }
 
}
